
































import { PaginationNames, Post, PostParameters } from '@kessel/core'
import { computed, defineComponent, onBeforeMount, onServerPrefetch, useMeta } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import { useMetaInfo } from '~/stores/metaInfo'
import { usePagination } from '~/stores/pagination'
import { usePublication } from '~/stores/publication_v2'

export default defineComponent({
  middleware: [
    // 'validateSubDomain',
    'landingModal',
    'referral',
  ],
  setup() {
    const { publication } = storeToRefs(usePublication())
    const { setStateParameters, syncPagination, getPagination } = usePagination()
    const { getPublicationMeta } = useMetaInfo()

    const posts = computed(() => getPagination(PaginationNames.NEWSLETTER_POSTS).items as Post[])
    const postsPinned = computed(() => getPagination(PaginationNames.NEWSLETTER_POSTS_PINNED).items as Post[])

    setStateParameters(PaginationNames.NEWSLETTER_POSTS, {
      promise: 'getPostsV2',
      promiseParameters: {
        filter: 'published',
        sort: 'published_at',
        size: 10,
        publicationId: publication.value?.id as string,
      } as PostParameters,
    })

    setStateParameters(PaginationNames.NEWSLETTER_POSTS_PINNED, {
      promise: 'getPostsV2',
      promiseParameters: {
        size: 1,
        filter: 'pinned',
        publicationId: publication.value?.id as string,
      } as PostParameters,
    })

    onServerPrefetch(async () => {
      await syncPagination({ name: PaginationNames.NEWSLETTER_POSTS_PINNED, page: 1 }, true)
      await syncPagination({ name: PaginationNames.NEWSLETTER_POSTS, page: 1 }, true)
    })

    onBeforeMount(async () => {
      await syncPagination({ name: PaginationNames.NEWSLETTER_POSTS_PINNED, page: 1 }, true)
      await syncPagination({ name: PaginationNames.NEWSLETTER_POSTS, page: 1 }, true)
    })

    useMeta(getPublicationMeta(publication.value))
    return {
      postsPinned,
      posts,
      PaginationNames,
      syncPagination,
    }
  },
  head: {},
})
