




























import { PaginationNames } from '@kessel/core'
import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<Object[]>,
      required: true,
      validator: (items: Object[]) => {
        return items.every((item) => {
          return 'id' in item
        })
      },
    },
    name: {
      type: String as PropType<PaginationNames>,
      required: true,
    },
    itemRenderer: {
      type: String,
      required: true,
      default: 'CommentPreview',
    },
  },
})
