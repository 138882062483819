






import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    title: { type: String, default: null },
  },
})
