












































































import { computed, defineComponent, onMounted, onUnmounted, ref, toRefs, watch } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import debounce from 'lodash/debounce'
import { usePagination } from '~/stores/pagination'
import KButton from '~/components/buttons/KButton.vue'

export default defineComponent({
  components: { KButton },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { name } = toRefs(props)
    const { getPagination, maxPage, hasNextPage, hasPrevPage, getInterval } = storeToRefs(usePagination())

    const pagination = computed(() => getPagination.value(name.value))

    const elementShowMore = ref<HTMLElement>()
    const observer = ref<IntersectionObserver | Object>({})
    const hovering = ref(false)

    const handleScroll = () => {
      if (hovering.value && hasNextPage.value(name.value) && !pagination.value.loading) {
        emit('scrolled', { name: name.value, page: pagination.value.page + 1 })
      }
    }

    watch(hovering, debounce(handleScroll, 500))

    onMounted(() => {
      if (pagination.value.scroll?.infinite && pagination.value.scroll?.auto) {
        observer.value = new IntersectionObserver(
          ([entry]) => {
            hovering.value = entry.isIntersecting
          },
          { threshold: 1 }
        )
        if (elementShowMore.value && observer.value instanceof IntersectionObserver) {
          observer.value.observe(elementShowMore.value)
        }
      }
    })

    onUnmounted(() => {
      if (observer.value instanceof IntersectionObserver) {
        observer.value?.disconnect()
      }
    })

    return {
      elementShowMore,
      pagination,
      hasPrevPage: computed(() => hasPrevPage.value(name.value)),
      hasNextPage: computed(() => hasNextPage.value(name.value)),
      getInterval: computed(() => getInterval.value(name.value, 1)),
      maxPage: computed(() => maxPage.value(name.value)),
      handleScroll,
    }
  },
})
